import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143')
];

export const server_loads = [0,5,3,4,6];

export const dictionary = {
		"/(public)": [~7,[2]],
		"/admin": [112,[5]],
		"/admin/blacklist": [~113,[5]],
		"/admin/blog": [~114,[5]],
		"/admin/categories": [~115,[5]],
		"/admin/cities": [~116,[5]],
		"/admin/parser-test": [117,[5]],
		"/admin/proxy": [118,[5]],
		"/admin/suppliers": [~119,[5]],
		"/admin/users": [~120,[5]],
		"/admin/website-requests": [~121,[5]],
		"/(public)/agreement-add-rules": [9,[2]],
		"/(public)/agreement": [8,[2]],
		"/(public)/billing/payment-result/[gate]/[result]": [~10],
		"/(public)/blog": [~11,[2]],
		"/(public)/blog/[slug]": [~12,[2]],
		"/(public)/catalog/add": [14,[2]],
		"/(public)/catalog/[[slug1]]/[[slug2]]": [~13,[2]],
		"/(public)/faq": [15,[2]],
		"/(public)/howto": [16,[2,3]],
		"/(public)/howto/additional-css-selectors": [17,[2,3]],
		"/(public)/howto/ai": [18,[2,3]],
		"/(public)/howto/ai/generate": [19,[2,3]],
		"/(public)/howto/ai/models": [~20,[2,3]],
		"/(public)/howto/ai/points": [21,[2,3]],
		"/(public)/howto/ai/prompt-writing": [22,[2,3]],
		"/(public)/howto/automata": [23,[2,3]],
		"/(public)/howto/automata/edit": [24,[2,3]],
		"/(public)/howto/button": [25,[2,3]],
		"/(public)/howto/erange": [26,[2,3]],
		"/(public)/howto/filestorage": [27,[2,3]],
		"/(public)/howto/format": [28,[2,3]],
		"/(public)/howto/format/(id)/advantshopv1format": [29,[2,3,4]],
		"/(public)/howto/format/(id)/advantshopv2format": [30,[2,3,4]],
		"/(public)/howto/format/(id)/amadyformat": [31,[2,3,4]],
		"/(public)/howto/format/(id)/bitrixcsvformat": [32,[2,3,4]],
		"/(public)/howto/format/(id)/cmss3format": [33,[2,3,4]],
		"/(public)/howto/format/(id)/cscartformat": [34,[2,3,4]],
		"/(public)/howto/format/(id)/csvformat": [35,[2,3,4]],
		"/(public)/howto/format/(id)/diskauntscsvformat": [36,[2,3,4]],
		"/(public)/howto/format/(id)/epicentrkymlformat": [37,[2,3,4]],
		"/(public)/howto/format/(id)/eshoperformat": [38,[2,3,4]],
		"/(public)/howto/format/(id)/facebookxmlfeedformat": [39,[2,3,4]],
		"/(public)/howto/format/(id)/hostcmsformat": [40,[2,3,4]],
		"/(public)/howto/format/(id)/insalescsvformat": [41,[2,3,4]],
		"/(public)/howto/format/(id)/insalesxlsformat": [42,[2,3,4]],
		"/(public)/howto/format/(id)/joomshoppingformat": [43,[2,3,4]],
		"/(public)/howto/format/(id)/kupivspformat": [44,[2,3,4]],
		"/(public)/howto/format/(id)/lpmotorformat": [45,[2,3,4]],
		"/(public)/howto/format/(id)/mikaiformat": [46,[2,3,4]],
		"/(public)/howto/format/(id)/mogutaformat": [47,[2,3,4]],
		"/(public)/howto/format/(id)/okalbumsformat": [48,[2,3,4]],
		"/(public)/howto/format/(id)/okaycmsformat": [49,[2,3,4]],
		"/(public)/howto/format/(id)/okformat": [50,[2,3,4]],
		"/(public)/howto/format/(id)/opencartcsvliteformat": [51,[2,3,4]],
		"/(public)/howto/format/(id)/opencartcsvpriceproformat": [52,[2,3,4]],
		"/(public)/howto/format/(id)/opencartexportimportformat": [53,[2,3,4]],
		"/(public)/howto/format/(id)/osclassadimporterformat": [54,[2,3,4]],
		"/(public)/howto/format/(id)/phpshopformat": [55,[2,3,4]],
		"/(public)/howto/format/(id)/pokupkiprostoformat": [56,[2,3,4]],
		"/(public)/howto/format/(id)/prestashopformat": [57,[2,3,4]],
		"/(public)/howto/format/(id)/promuaymlformat": [58,[2,3,4]],
		"/(public)/howto/format/(id)/rozetkaymlformat": [59,[2,3,4]],
		"/(public)/howto/format/(id)/serpokformat": [60,[2,3,4]],
		"/(public)/howto/format/(id)/shopifyformat": [61,[2,3,4]],
		"/(public)/howto/format/(id)/showcaseformat": [62,[2,3,4]],
		"/(public)/howto/format/(id)/simplacmsformat": [63,[2,3,4]],
		"/(public)/howto/format/(id)/storelandformat": [64,[2,3,4]],
		"/(public)/howto/format/(id)/stospformat": [65,[2,3,4]],
		"/(public)/howto/format/(id)/superpuperformat": [66,[2,3,4]],
		"/(public)/howto/format/(id)/tildacsvformat": [67,[2,3,4]],
		"/(public)/howto/format/(id)/tildaymlformat": [68,[2,3,4]],
		"/(public)/howto/format/(id)/uralcmscsvformat": [69,[2,3,4]],
		"/(public)/howto/format/(id)/ushopymlformat": [70,[2,3,4]],
		"/(public)/howto/format/(id)/vkalbumsformat": [71,[2,3,4]],
		"/(public)/howto/format/(id)/vkformat": [72,[2,3,4]],
		"/(public)/howto/format/(id)/webasystshopscriptformat": [73,[2,3,4]],
		"/(public)/howto/format/(id)/wixformat": [74,[2,3,4]],
		"/(public)/howto/format/(id)/woocommerceformat": [75,[2,3,4]],
		"/(public)/howto/format/(id)/xlsformat": [76,[2,3,4]],
		"/(public)/howto/format/(id)/xmlformat": [77,[2,3,4]],
		"/(public)/howto/format/(id)/ymlformat": [78,[2,3,4]],
		"/(public)/howto/mods": [79,[2,3]],
		"/(public)/howto/mods/cols-cut": [81,[2,3]],
		"/(public)/howto/mods/cols-remove": [82,[2,3]],
		"/(public)/howto/mods/cols-rename": [83,[2,3]],
		"/(public)/howto/mods/cols-replace": [84,[2,3]],
		"/(public)/howto/mods/cols-sort": [85,[2,3]],
		"/(public)/howto/mods/cols-split": [86,[2,3]],
		"/(public)/howto/mods/cols-strip-html": [87,[2,3]],
		"/(public)/howto/mods/cols": [80,[2,3]],
		"/(public)/howto/mods/copying": [88,[2,3]],
		"/(public)/howto/mods/deduplication": [89,[2,3]],
		"/(public)/howto/mods/filter": [90,[2,3]],
		"/(public)/howto/mods/images": [91,[2,3]],
		"/(public)/howto/mods/images/count": [92,[2,3]],
		"/(public)/howto/mods/images/cut": [93,[2,3]],
		"/(public)/howto/mods/images/format": [94,[2,3]],
		"/(public)/howto/mods/images/overlay": [95,[2,3]],
		"/(public)/howto/mods/images/resize": [96,[2,3]],
		"/(public)/howto/mods/images/text": [97,[2,3]],
		"/(public)/howto/mods/price": [98,[2,3]],
		"/(public)/howto/mods/publish": [99,[2,3]],
		"/(public)/howto/mods/sort": [100,[2,3]],
		"/(public)/howto/parser-categories": [101,[2,3]],
		"/(public)/howto/parser-compare": [102,[2,3]],
		"/(public)/howto/referral": [103,[2,3]],
		"/(public)/howto/social": [104,[2,3]],
		"/login": [~122],
		"/my": [~123,[6]],
		"/my/catalog/add": [125,[6]],
		"/my/catalog/add/req": [126,[6]],
		"/my/catalog/add/test": [127,[6]],
		"/my/catalog/private": [~128,[6]],
		"/my/catalog/[[slug1]]/[[slug2]]": [~124,[6]],
		"/my/parser": [129,[6]],
		"/my/parser/automata": [131,[6]],
		"/my/parser/automata/[automataId]": [~132,[6]],
		"/my/parser/compare/[queueId1]/[queueId2]": [~133,[6]],
		"/my/parser/credentials": [134,[6]],
		"/my/parser/filestorage": [~135,[6]],
		"/my/parser/filestorage/add": [~137,[6]],
		"/my/parser/filestorage/[id]/edit": [~136,[6]],
		"/my/parser/result/[queueId]": [~138,[6]],
		"/my/parser/[domain]": [~130,[6]],
		"/my/profile": [139,[6]],
		"/my/profile/tariffs": [~140,[6]],
		"/my/supplier/my": [~141,[6]],
		"/my/supplier/my/[id]": [~142,[6]],
		"/my/supplier/tariffs": [143,[6]],
		"/(public)/privacy-policy": [105,[2]],
		"/(public)/reviews": [~106,[2]],
		"/(public)/supplier": [107,[2]],
		"/(public)/supplier/widget": [108,[2]],
		"/(showcase)/s/[id]": [~111],
		"/(public)/tariffs": [~109,[2]],
		"/(public)/what-can": [110,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';